
















































































































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { coreApi } from "@/lib/backend";
import type { PaginatedScheduledNotificationViewModel } from "@/lib/backend/core.api";
import { buttonVariants } from "@/lib/pfg/components/button";
import Badge from "@/lib/pfg/vue2/badge/Badge.vue";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import CardContent from "@/lib/pfg/vue2/card/CardContent.vue";
import CardHeader from "@/lib/pfg/vue2/card/CardHeader.vue";
import CardTitle from "@/lib/pfg/vue2/card/CardTitle.vue";
import Input from "@/lib/pfg/vue2/input/Input.vue";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import Skeleton from "@/lib/pfg/vue2/skeleton/Skeleton.vue";
import Textarea from "@/lib/pfg/vue2/textarea/Textarea.vue";
import { formatDate } from "@/utils/date";

export default defineComponent({
	components: {
		Badge,
		Input,
		Card,
		CardHeader,
		CardTitle,
		CardContent,
		Label,
		Textarea,
		Skeleton,
	},
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		messageId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data } = useQuery({
			queryKey: ["member", props.memberId, "scheduled-notifications"] as const,
			queryFn: async (context) =>
				await coreApi.members
					.memberNotificationControllerGetScheduledNotifications(String(context.queryKey[1]), {
						signal: context.signal,
					})
					.then((response) => response.data),
			select: (data: PaginatedScheduledNotificationViewModel) =>
				data.data.find((scheduledNotification) => scheduledNotification.id === props.messageId),
		});

		return {
			status,
			data,
			formatDate,
			buttonVariants,
		};
	},
	watch: {
		status(newValue: "loading" | "success" | "error") {
			if (newValue !== "success" || !!this.data) {
				return;
			}

			this.$router.replace({ name: "Gebruiker berichten" });
		},
	},
});
